export const columnsDataDevelopment = [
  {
    Header: 'SUBMITTED AT',
    accessor: 'submitted_at',
  },
  {
    Header: 'KOL ID',
    accessor: 'kol_id',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'FULL NAME',
    accessor: 'full_name',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  {
    Header: 'PAYMENT METHOD',
    accessor: 'payment_method',
  },
  {
    Header: 'TOTAL CONTRACT PRICE',
    accessor: 'total_contract_price',
  },
  {
    Header: 'NIK',
    accessor: 'nik',
  },
  {
    Header: 'IDENTITY TYPE',
    accessor: 'identity_type',
  },
  {
    Header: 'IDENTITY DOC',
    accessor: 'identity_doc',
  },
  {
    Header: 'GROSS/NETT',
    accessor: 'gross_nett',
  },
  {
    Header: 'TAX TYPE',
    accessor: 'tax_type',
  },
  {
    Header: 'TAX PERCENTAGE',
    accessor: 'tax_percentage',
  },
  {
    Header: 'TOTAL CONTRACT PRICE AFTER TAX',
    accessor: 'total_contract_price_after_tax',
  },
  {
    Header: 'SIGNED CONTRACT DOCUMENT',
    accessor: 'signed_contract_document',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
