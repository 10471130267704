export const columnsDataDevelopment = [
  {
    Header: 'SUBMITTED AT',
    accessor: 'submitted_at',
  },
  {
    Header: 'KOL ID',
    accessor: 'kol_id',
  },
  {
    Header: 'USERNAME',
    accessor: 'username',
  },
  {
    Header: 'PLATFORM',
    accessor: 'platform',
  },
  {
    Header: 'FULL NAME',
    accessor: 'full_name',
  },
  {
    Header: 'NIK',
    accessor: 'nik',
  },
  {
    Header: 'WHATSAPP NUMBER',
    accessor: 'whatsapp_number',
  },
  {
    Header: 'BANK NAME',
    accessor: 'bank_name',
  },
  {
    Header: 'BANK ACCOUNT NAME',
    accessor: 'bank_account_name',
  },
  {
    Header: 'BANK ACCOUNT NUMBER',
    accessor: 'bank_account_number',
  },
  {
    Header: 'BORN AT',
    accessor: 'born_at',
  },
  {
    Header: 'BIRTH DATE',
    accessor: 'birth_date',
  },
  {
    Header: 'HOME ADDRESS',
    accessor: 'home_address',
  },
  {
    Header: 'TOTAL CONTRACT PRICE',
    accessor: 'total_contract_price',
  },
  {
    Header: 'TOTAL CONTRACT SLOT',
    accessor: 'total_contract_slot',
  },
  {
    Header: 'INVOICE FILE',
    accessor: 'invoice_file',
  },
  {
    Header: 'ACTION',
    accessor: 'action',
  },
];
