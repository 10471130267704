import React, { Fragment, useCallback, useState } from 'react';
import { Calendar as CalendarBig, dateFnsLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { Box, 
  Button, 
  Flex, 
  useDisclosure,  
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Text, 
  SimpleGrid,
  GridItem,
  Select
} from '@chakra-ui/react';
import "assets/css/ReactBigCalendar.css";
import enUS from 'date-fns/locale/en-US';
import { MdAddCircle } from "react-icons/md"
import { DatePicker } from '@orange_digital/chakra-datepicker';

export const locales = {
  'en-US': enUS,
  // Add other locales here
};

const DragAndDropCalendar = withDragAndDrop(CalendarBig)

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date()),
  getDay,
  locales,
});

const events = [
  {
    id: 1,
    title: 'Meeting 1',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 2,
    title: 'Meeting 2',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 3,
    title: 'Meeting 3',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 4,
    title: 'Meeting 4',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 5,
    title: 'Meeting 5',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 6,
    title: 'Meeting 6',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 7,
    title: 'Meeting 7',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 8,
    title: 'Meeting 8',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 9,
    title: 'Meeting 9',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 10,
    title: 'Meeting 10',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 11,
    title: 'Meeting 11',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 12,
    title: 'Meeting 12',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 13,
    title: 'Meeting 13',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 14,
    title: 'Meeting 14',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 15,
    title: 'Meeting 15',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 16,
    title: 'Meeting 16',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 17,
    title: 'Meeting 17',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 18,
    title: 'Meeting 18',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 19,
    title: 'Meeting 19',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  {
    id: 20,
    title: 'Meeting 20',
    start: new Date(2024, 8, 20, 10, 0), // July 14, 2024 at 10:00 AM
    end: new Date(2024, 8, 20, 12, 0), // July 14, 2024 at 12:00 PM
  },
  // Add more events here
];

export default function CalendarComponent() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [myEvents, setMyEvents] = useState(events)
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const { isOpen: modalEventIsOpen, onOpen: modalEventOnOpen, onClose: modalEventOnClose } = useDisclosure();
  const { isOpen: modalAddIsOpen, onOpen: modalAddOnOpen, onClose: modalAddOnClose } = useDisclosure();

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true
      }
      if (allDay && !droppedOnAllDaySlot) {
          event.allDay = false;
      }

      console.log("event changed", event, start, end)

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end, allDay: event.allDay }]
      })
    },
    [setMyEvents]
  )

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })
    },
    [setMyEvents]
  )

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    modalEventOnOpen();
  };

  return (
    <>
      <Text fontWeight='bold' fontSize='34px'>
        Timeline
      </Text>
      <Fragment>
        <Flex justifyContent="flex-end" mt={0}>
          <Button rightIcon={<MdAddCircle />} onClick={modalAddOnOpen}>
            Add New
          </Button>
        </Flex>
        <Box mt={5}>
          <DragAndDropCalendar
            date={currentDate}
            defaultView={Views.MONTH}
            events={myEvents}
            localizer={localizer}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            onNavigate={setCurrentDate}
            popup
            resizable
            showAllEvents={true}
            onSelectEvent={handleSelectEvent}
            views={['month']}
          />
        </Box>

        {selectedEvent && (
          <Modal isOpen={modalEventIsOpen} onClose={modalEventOnClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent maxWidth={{ base: '300px', md: '500px' }}>
              <ModalHeader>{selectedEvent.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <p><strong>Start:</strong> {selectedEvent.start.toString()}</p>
                <p><strong>End:</strong> {selectedEvent.end.toString()}</p>
                <p><strong>Description:</strong> {selectedEvent.description}</p>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={modalEventOnClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        <Modal isOpen={modalAddIsOpen} onClose={modalAddOnClose} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth={{ base: "1000px" }}>
            <ModalHeader>Add New Listing</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <SimpleGrid columns={3} spacing={5} mb="20px">
            <GridItem>
              <form
                id="new-note-1"
                onSubmit={(event) => {
                  event.preventDefault();
                  alert("Submitted form 1");
                }}
              >
                <FormControl>
                  <FormLabel fontSize='sm' fontWeight='500'>Username/Link</FormLabel>
                  <Input type="email" 
                    borderColor="grey"
                  />
                </FormControl>
              </form>
              </GridItem>
              <GridItem>
              <form
                id="new-note-2"
                onSubmit={(event) => {
                  event.preventDefault();
                  alert("Submitted form 2");
                }}
              >
                <FormControl>
                  <FormLabel fontSize='sm' fontWeight='500'>Platform</FormLabel>
                  <Input type="email" 
                    borderColor="grey"
                  />
                </FormControl>
              </form>
              </GridItem>
              
              <GridItem>
              <form
                id="new-note-3"
                onSubmit={(event) => {
                  event.preventDefault();
                  alert("Submitted form 3");
                }}
              >
                <FormControl>
                  <FormLabel fontSize='sm' fontWeight='500'>KOL Category</FormLabel>
                  <Input type="email" 
                    borderColor="grey"
                  />
                </FormControl>
              </form>
              </GridItem>
            </SimpleGrid>
            <Text mb="10px" fontSize='md' fontWeight='800'>Rate Card</Text>
            <SimpleGrid columns={6} rows={2} spacingX={5} spacingY={5} mb="20px">
              <GridItem>
                <form
                  id="new-note-1"
                  onSubmit={(event) => {
                    event.preventDefault();
                    alert("Submitted form 1");
                  }}
                >
                  <FormControl>
                    <FormLabel fontSize='sm' fontWeight='500'>Slot</FormLabel>
                    <Input type="email" borderColor="grey" placeholder='1'/>
                  </FormControl>
                </form>
              </GridItem>
              <GridItem>
                <form
                  id="new-note-1"
                  onSubmit={(event) => {
                    event.preventDefault();
                    alert("Submitted form 1");
                  }}
                >
                  <FormControl>
                    <FormLabel fontSize='sm' fontWeight='500'>Set tanggal</FormLabel>
                    <DatePicker initialValue={new Date()} borderColor="grey "/>
                  </FormControl>
                </form>
              </GridItem>
              <GridItem colSpan={2}>
                <form
                  id="new-note-1"
                  onSubmit={(event) => {
                    event.preventDefault();
                    alert("Submitted form 1");
                  }}
                >
                  <FormControl>
                    <FormLabel fontSize='sm' fontWeight='500'>Brief identifier</FormLabel>
                    <Select placeholder='' color="black">
                      <option key="a">Joged Tiktok</option>
                      <option key="a">Joged Tiktok 2</option>
                    </Select>
                  </FormControl>
                </form>
              </GridItem>
              <GridItem colSpan={2}>
                <form
                  id="new-note-1"
                  onSubmit={(event) => {
                    event.preventDefault();
                    alert("Submitted form 1");
                  }}
                >
                  <FormControl>
                    <FormLabel fontSize='sm' fontWeight='500'>Product attach</FormLabel>
                    <Select placeholder='' color="black">
                      <option key="a">Skincare Alami</option>
                      <option key="a">Skincare Alami 2</option>
                    </Select>
                  </FormControl>
                </form>
              </GridItem>
            </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={modalAddOnClose}>
                Submit
              </Button>
              <Button variant='ghost' onClick={modalAddOnClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Fragment>
    </>
  );
}
