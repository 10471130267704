import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, FieldArray, Field } from 'formik';
import useListing from 'hooks/useListing';

const readOnlyColor = {
  bg: 'gray.100',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

const FormComponent = ({ cell, onClose }) => {
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const { kolCategories, getKolCategories, postListingManager } = useListing();
  const [approvalStatus, setApprovalStatus] = useState('');
  const [note, setNote] = useState('');
  const toast = useToast();

  useEffect(() => {
    console.log(cell);
  }, []);

  const initialValues = {
    username: cell.username,
    platform: cell.platform,
    kol_category: cell.kol_category,
    rate_card: cell.rate_card,
    rate_card_note: cell.rate_card.note,
    add_cost: cell.add_cost,
    note: cell.note,
  };

  const onSubmitApproval = async (values) => {
    const transformedData = {
      status: 'approved',
      dealing_type: 'new',
      business_type_id: businessTypeId,
      negotiated_cost: {
        rate_cards: values.rate_card.map((cost) => ({
          rate_card_id: cost.id,
          price: Number(cost.nego_price),
          slot: Number(cost.slot),
        })),
        add_costs: values.add_cost.map((cost) => ({
          add_cost_id: cost.id,
          price: Number(cost.add_cost_nego_price),
        })),
      },
      note: values.approval_note || '',
    };

    try {
      console.log('Transformed Data', transformedData);
      await postListingManager(transformedData, cell.id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onHoldReject = async () => {
    const transformedData = {
      status: approvalStatus,
      dealing_type: 'new',
      action_note: note,
    };

    console.log(transformedData);
    try {
      console.log('Transformed Data', transformedData);
      await postListingManager(transformedData, cell.id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <FormControl mb={4}>
          <FormLabel>Approval Status</FormLabel>
          <Select
            placeholder="Select action"
            onChange={(e) => setApprovalStatus(e.target.value)}
            value={approvalStatus}
          >
            <option value="hold">Hold</option>
            <option value="rejected">Reject</option>
            <option value="approved">Approve</option>
          </Select>
        </FormControl>
        {/* when user choose approval hold/rejected */}
        {(approvalStatus === 'hold' || approvalStatus === 'rejected') && (
          <>
            <SimpleGrid columns={3} mb="20px">
              <GridItem colSpan={3}>
                <FormControl mb={4}>
                  <FormLabel>Note</FormLabel>
                  <Input
                    placeholder="Enter a note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormControl>
              </GridItem>
            </SimpleGrid>

            <Box textAlign="right">
              <Button
                colorScheme="purple"
                type="submit"
                onClick={() => onHoldReject()}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
        {/* when user choose approved*/}
        {approvalStatus === 'approved' && (
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmitApproval}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <SimpleGrid columns={4} spacingX={5} spacingY={5} mb="20px">
                  {/* Row 1 */}
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>Username</FormLabel>
                      <Input
                        readOnly
                        value={values.username.socmed_id}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>Platform</FormLabel>
                      <Input
                        readOnly
                        value={values.platform}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4}>
                      <FormLabel>KOL Category</FormLabel>
                      <Input
                        readOnly
                        value={values.kol_category}
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl mb={4} isRequired={true}>
                      <FormLabel>Business Type</FormLabel>
                      <Select
                        name="business_type"
                        placeholder="Select type"
                        onChange={(e) => {
                          handleChange(e); // Call handleChange with the event
                          setBusinessTypeId(e.target.selectedIndex); // Set the index
                        }}
                        value={values.business_type}
                        style={{ textTransform: 'capitalize' }}
                      >
                        <option value="regular">Regular</option>
                        <option value="executive">Executive</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Rate Card
                    </FormLabel>
                  </Box>
                </Flex>
                <FieldArray name="rate_card">
                  {({ remove, push }) => (
                    <>
                      {values.rate_card.map((rateCard, index) => (
                        <SimpleGrid
                          columns={4}
                          spacingX={5}
                          spacingY={5}
                          mb="20px"
                          key={index}
                        >
                          <GridItem>
                            <FormLabel>Type</FormLabel>
                            <Input
                              value={rateCard.type}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Asli</FormLabel>
                            <Input
                              value={rateCard.price}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormControl isRequired={true}>
                              <FormLabel>Harga Nego</FormLabel>
                              <Field
                                name={`rate_card[${index}].nego_price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl isRequired={true}>
                              <FormLabel>Slot</FormLabel>
                              <Field
                                name={`rate_card[${index}].slot`}
                                as={Input}
                                placeholder="Enter slot"
                              />
                            </FormControl>
                          </GridItem>
                          {/* Row 2 */}
                          <GridItem colSpan={4}>
                            <FormControl mb={4}>
                              <FormLabel>Keterangan</FormLabel>
                              <Input
                                value={rateCard.note}
                                readOnly
                                _readOnly={readOnlyColor}
                              />
                            </FormControl>
                          </GridItem>
                        </SimpleGrid>
                      ))}
                    </>
                  )}
                </FieldArray>

                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Add Cost
                    </FormLabel>
                  </Box>
                </Flex>
                <FieldArray name="add_cost">
                  {({ remove, push }) => (
                    <>
                      {values.add_cost.map((addCost, index) => (
                        <SimpleGrid
                          columns={3}
                          spacingX={5}
                          spacingY={5}
                          mb="20px"
                          key={index}
                        >
                          <GridItem>
                            <FormLabel>Type</FormLabel>
                            <Input
                              value={addCost.type}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Asli</FormLabel>
                            <Flex>
                              <Input
                                value={addCost.price}
                                readOnly
                                _readOnly={readOnlyColor}
                              />
                            </Flex>
                          </GridItem>
                          <GridItem>
                            <FormLabel>Harga Nego</FormLabel>
                            <Flex>
                              <Field
                                name={`add_cost[${index}].add_cost_nego_price`}
                                as={Input}
                                placeholder="Enter price"
                              />
                            </Flex>
                          </GridItem>
                        </SimpleGrid>
                      ))}
                    </>
                  )}
                </FieldArray>

                <SimpleGrid columns={3} mb="20px">
                  <GridItem colSpan={3}>
                    <FormControl mb={4}>
                      <FormLabel fontSize="lg" fontWeight="bold">
                        Keterangan Kerjasama
                      </FormLabel>
                      <Input
                        value={cell.note}
                        readOnly
                        _readOnly={readOnlyColor}
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
                <SimpleGrid columns={3} mb="20px">
                  <GridItem colSpan={3}>
                    <FormControl mb={4}>
                      <FormLabel fontSize="lg" fontWeight="bold">
                        Approval Note
                      </FormLabel>
                      <Field
                        name="approval_note"
                        as={Input}
                        placeholder="Enter notes"
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>

                <Box textAlign="right">
                  <Button colorScheme="purple" type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
