import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'utils/api';

export default function useListing() {
  const [listing, setListing] = useState([]);
  const [totalListing, setTotalListing] = useState(0);
  const [kolCategories, setKolCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    getKolCategories();
  }, []);

  const getListing = async(page, limit) => {
    let value = [];
    try {
      let { data } = await api.get(
        `/listing/data/all?page=${page}&limit=${limit}`,
      );

      setTotalListing(data.data.total_items);
      data = data.data.rows;

      data.map((val, key) => {
        const id = val.id;
        const listing_date = val.created_at?.split('T')[0] || '';
        const socmed_id = val.kol?.username ?? '';
        const link = val.kol?.link ?? '';
        const platform = val.kol?.platform ?? '';
        const note = val.action?.[0]?.action_note?.note ?? '';
        const cpm = val.kol?.cost_per_mile?.[0]?.value ?? 0;
        const status = val.action?.[0]?.action_status ?? '';
        const kol_category = val.kol?.kol_category?.name ?? '';
        const add_cost = val.cost.add_cost;
        const rate_card = val.cost.rate_card;
        const issued_by = val.user_issued_by?.name ?? '';

        const object = {
          id,
          listing_date,
          username: {
            socmed_id,
            link,
          },
          platform,
          rate_card,
          add_cost,
          kol_category,
          note,
          cpm,
          status,
          action: ['edit', 'trash'],
          issued_by,
        };
        value.push(object);
      });

      setListing(value);
      console.log(value);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const getKolCategories = async () => {
    try {
      let { data } = await api.get(`/listing/kol-categories`);

      setKolCategories(data.data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const postListingKol = async (body) => {
    try {
      let { data } = await api.post(`/listing`, body);

      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  const postListingManager = async (body, index) => {
    try {
      let { data } = await api.post(`/listing/approval/${index}`, body);

      console.log(data);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
      throw error.response.data.message;
    } finally {
      setLoading(false);
    }
  };

  return {
    listing,
    isLoading,
    error,
    kolCategories,
    totalListing,
    getListing,
    postListingKol,
    getKolCategories,
    postListingManager,
  };
}
