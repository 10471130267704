import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { MdAddCircle } from 'react-icons/md';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import useListing from 'hooks/useListing';

const rateCardOptions = {
  tiktok: ['video', 'live'],
  instagram: ['story', 'feeds', 'reels'],
  twitter: ['tweet'],
};

const addCostOptions = {
  tiktok: ['Owning', 'Yellow Cart', 'Code Boost Ad'],
  instagram: ['Owning'],
  twitter: ['Owning'],
};

const validationSchema = Yup.object({
  rateCards: Yup.array().of(
    Yup.object({
      type: Yup.string().required('Required'),
      price: Yup.number().required('Required').min(0, 'price must be positive'),
    }),
  ),
  addCosts: Yup.array().of(
    Yup.object({
      type: Yup.string().required('Required'),
      price: Yup.number().required('Required').min(0, 'price must be positive'),
    }),
  ),
});

const FormComponent = ({ onClose }) => {
  const toast = useToast();
  const [platform, setPlatform] = useState('');
  const [availableRateCards, setAvailableRateCards] = useState([]);
  const [availableCost, setAvailableCost] = useState([]);
  const [kolCategoryIndex, setKolCategoryIndex] = useState(0);
  const { kolCategories, getKolCategories, postListingKol } = useListing();


  useEffect(() => {
    getKolCategories();
  }, []);

  const handleRateCardAdd = (type, values, setFieldValue) => {
    setFieldValue('rateCards', [
      ...values.rateCards,
      { type, price: '', rate_card_note: '' },
    ]);
    setAvailableRateCards(
      availableRateCards.filter((option) => option !== type),
    );
  };

  const handleCostAdd = (type, values, setFieldValue) => {
    setFieldValue('addCosts', [...values.addCosts, { type, price: '' }]);
    setAvailableCost(availableCost.filter((option) => option !== type));
  };

  const handlePlatformChange = (event, setFieldValue, values) => {
    const selectedPlatform = event.target.value;
    setPlatform(selectedPlatform);
    setAvailableRateCards(rateCardOptions[selectedPlatform]);
    setAvailableCost(addCostOptions[selectedPlatform]);
    setFieldValue('platform', selectedPlatform);
    setFieldValue('rateCards', []);
    setFieldValue('addCosts', []);

    // Auto-fill link if username is already filled
    if (values.username) {
      let link = '';
      switch (selectedPlatform) {
        case 'tiktok':
          link = `https://tiktok.com/@${values.username}`;
          break;
        case 'instagram':
          link = `https://instagram.com/${values.username}`;
          break;
        case 'twitter':
          link = `https://x.com/${values.username}`;
          break;
        default:
          break;
      }
      setFieldValue('link', link);
    }
  };

  const handleUsernameChange = (event, setFieldValue, values) => {
    const username = event.target.value;
    setFieldValue('username', username);

    // Auto-fill link if platform is already selected
    if (values.platform) {
      let link = '';
      switch (values.platform) {
        case 'tiktok':
          link = `https://tiktok.com/@${username}`;
          break;
        case 'instagram':
          link = `https://www.instagram.com/${username}`;
          break;
        case 'twitter':
          link = `https://x.com/${username}`;
          break;
        default:
          break;
      }
      setFieldValue('link', link);
    }
  };

  const handleLinksChange = (event, setFieldValue) => {
    const link = event.target.value;
    let username = '';
    let selectedPlatform = '';

    if (link.includes('tiktok.com')) {
      selectedPlatform = 'tiktok';
      username = link.split('@')[1];
    } else if (link.includes('instagram.com')) {
      selectedPlatform = 'instagram';
      username = link.split('instagram.com/')[1];
    } else if (link.includes('x.com')) {
      selectedPlatform = 'twitter';
      username = link.split('x.com/')[1];
    }

    setPlatform(selectedPlatform);

    setFieldValue('username', username);
    setFieldValue('platform', selectedPlatform);
    setFieldValue('link', link);

    setAvailableRateCards(rateCardOptions[selectedPlatform]);
    setAvailableCost(addCostOptions[selectedPlatform]);
  };

  const initialValues = {
    username: '',
    platform: '',
    link: '',
    kol_category: '',
    rateCards: [],
    rate_card_note: '',
    addCosts: [],
    note: '',
  };

  const onSubmit = async (values) => {
    const transformedData = {
      cost: {
        rate_card: values.rateCards.map((rateCard) => ({
          type: rateCard.type.toLowerCase().replace(' ', '_'),
          price: rateCard.price,
          note: rateCard.rate_card_note,
        })),
        add_cost: values.addCosts.map((cost) => ({
          type: cost.type.toLowerCase().replace(' ', '_'),
          price: cost.price,
        })),
      },
      kol: {
        username: values.username,
        platform: values.platform,
        link: values.link,
        category: kolCategoryIndex,
        current_status: 1,
      },
      note: values.note || '',
    };

    console.log('Transformed Data', transformedData);

    try {
      await postListingKol(transformedData);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setTimeout(() => {
        onClose();
      }, 3000); // Same duration as the toast
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={3} spacingX={5} spacingY={5} mb="20px">
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Username</FormLabel>
                    <Input
                      name="username"
                      placeholder="Enter username"
                      value={values.username}
                      onChange={(e) =>
                        handleUsernameChange(e, setFieldValue, values)
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      name="platform"
                      placeholder="Select platform"
                      onChange={(e) =>
                        handlePlatformChange(e, setFieldValue, values)
                      }
                      value={values.platform}
                    >
                      <option value="tiktok">Tiktok</option>
                      <option value="instagram">Instagram</option>
                      <option value="twitter">Twitter</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>KOL Category</FormLabel>
                    <Select
                      name="kol_category"
                      placeholder="Select KOL category"
                      onChange={(e) => {
                        handleChange(e); // Call handleChange with the event
                        setKolCategoryIndex(e.target.selectedIndex); // Set the index
                      }}
                      value={values.kol_category}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {kolCategories &&
                        kolCategories.map((item, key) => (
                          <option key={key} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>

                {/* Row 2 */}
                <GridItem colSpan={3}>
                  <FormControl mb={4}>
                    <FormLabel>Links</FormLabel>
                    <Input
                      name="link"
                      placeholder="Enter or select link"
                      value={values.link}
                      onChange={(e) => handleLinksChange(e, setFieldValue)}
                    />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              {platform && (
                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Rate Card
                    </FormLabel>
                  </Box>
                  <Box>
                    <FormControl mb={4}>
                      <Menu>
                        <MenuButton as={Button} leftIcon={<MdAddCircle />}>
                          Add
                        </MenuButton>
                        <MenuList>
                          {availableRateCards.map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() =>
                                handleRateCardAdd(option, values, setFieldValue)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </FormControl>
                  </Box>
                </Flex>
              )}

              <FieldArray name="rateCards">
                {({ remove, push }) => (
                  <>
                    {values.rateCards.map((rateCard, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input value={rateCard.type} readOnly />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormLabel>Price</FormLabel>
                          <Flex>
                            <Field
                              name={`rateCards[${index}].price`}
                              as={Input}
                              placeholder="Enter price"
                            />
                            <Button
                              ml={2}
                              colorScheme="red"
                              onClick={() => {
                                remove(index);
                                setAvailableRateCards([
                                  ...availableRateCards,
                                  rateCard.type,
                                ]);
                              }}
                            >
                              Remove
                            </Button>
                          </Flex>
                        </GridItem>
                        {/* Row 2 */}
                        <GridItem colSpan={3}>
                          <FormControl mb={4}>
                            <FormLabel>Note</FormLabel>
                            <Field
                              name={`rateCards[${index}].rate_card_note`}
                              as={Input}
                              placeholder="Enter price"
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              {platform && (
                <Flex justify="space-between">
                  <Box>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Additional Cost
                    </FormLabel>
                  </Box>
                  <Box>
                    <FormControl mb={4}>
                      <Menu>
                        <MenuButton as={Button} leftIcon={<MdAddCircle />}>
                          Add
                        </MenuButton>
                        <MenuList>
                          {availableCost.map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() =>
                                handleCostAdd(option, values, setFieldValue)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </FormControl>
                  </Box>
                </Flex>
              )}

              <FieldArray name="addCosts">
                {({ remove, push }) => (
                  <>
                    {values.addCosts.map((cost, index) => (
                      <SimpleGrid
                        columns={3}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                        key={index}
                      >
                        <GridItem>
                          <FormLabel>Type</FormLabel>
                          <Input value={cost.type} readOnly />
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormLabel>Price</FormLabel>
                          <Flex>
                            <Field
                              name={`addCosts[${index}].price`}
                              as={Input}
                              placeholder="Enter price"
                            />
                            <Button
                              ml={2}
                              colorScheme="red"
                              onClick={() => {
                                remove(index);
                                setAvailableCost([...availableCost, cost.type]);
                              }}
                            >
                              Remove
                            </Button>
                          </Flex>
                        </GridItem>
                      </SimpleGrid>
                    ))}
                  </>
                )}
              </FieldArray>

              <SimpleGrid columns={3} mb="20px">
                <GridItem colSpan={3}>
                  <FormControl mb={4}>
                    <FormLabel fontSize="lg" fontWeight="bold">
                      Notes
                    </FormLabel>
                    <Field name="note" as={Input} placeholder="Enter notes" />
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Box textAlign="right">
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
