import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Text,
  useColorModeValue,
  Select,
  useToast,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';

// Assets
import illustration_ from 'assets/img/auth/auth-3.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuth';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import useKolForm from 'hooks/useKolForm';
import Loader from 'components/loader/Loader';
import CustomInputForm from 'components/fields/TextInputForm';
import { InfoIcon } from '@chakra-ui/icons';

// Chakra color mode
const textColorSecondary = 'gray.400';
const borderColor = 'gray.300';
const readOnlyColor = {
  bg: 'transparant',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

export default function KolFormSecond() {
  const textColor = useColorModeValue('navy.700', 'white');
  const { isError, isLoading, kolData, getSecondKolFormData, postSecondKolForm } =
    useKolForm();
  const { id } = useParams();
  const toast = useToast();

  const [taxType, setTaxType] = useState(null);
  const [skbBadan, setSkbBadan] = useState('true');
  const [suketPP23, setSuketPP23] = useState('false');
  const [identity, setIdentity] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [grossNett, setGrossNett] = useState(null);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const iconRef = useRef(null);
  const toggleTooltip = () => setTooltipOpen((prev) => !prev);

  // Close the tooltip when clicking outside of the referenced element
  useOutsideClick({
    ref: iconRef,
    handler: () => setTooltipOpen(false),
  });

  useEffect(() => {
    try {
      getSecondKolFormData(id);
      calculatePPh21();
    } catch (error) {}
  }, [
    id,
    isError,
    isLoading,
    identity,
    grossNett,
    taxType,
    taxPercentage,
    finalAmount,
    skbBadan,
    suketPP23,
  ]);

  const initialValues = {
    username: kolData?.username,
    platform: kolData?.platform,
    brand: kolData?.brand,
    business_type: kolData?.business_type,
    pic: kolData?.pic,
    full_name: kolData?.full_name,
    email: kolData?.email,
    whatsapp_number: kolData?.whatsapp_number,
    home_address: kolData?.home_address,
    bank_name: kolData?.bank_name,
    bank_account_name: kolData?.bank_account_name,
    bank_account_number: kolData?.bank_account_number,
    invoice_file: kolData?.invoice_file,
    spk_mou_file: kolData?.spk_mou_file,
    payment_methods: kolData?.payment_methods,
    dealing_rules: kolData?.dealing_rules,
    contract_total_transaction: kolData?.contract_total_transaction,
  };

  const calculatePPh21 = () => {
    const totalTranscation = kolData?.contract_total_transaction;
    let taxPercentage = 0;

    // Tax brackets (in IDR)
    const taxBrackets = [
      { maxIncome: 60000000, rate: 0.05 }, // 5% for income up to IDR 60,000,000
      { maxIncome: 250000000, rate: 0.15 }, // 15% for income up to IDR 250,000,000
      { maxIncome: 500000000, rate: 0.25 }, // 25% for income up to IDR 500,000,000
      { maxIncome: 5000000000, rate: 0.3 }, // 30% for income up to IDR 5,000,000,000
      { maxIncome: Infinity, rate: 0.35 }, // 35% for income above IDR 5,000,000,000
    ];
    let totalTax = 0;
    let remainingIncome = totalTranscation;
    for (let i = 0; i < taxBrackets.length; i++) {
      const bracket = taxBrackets[i];
      if (remainingIncome > 0) {
        const taxableIncome = Math.min(
          remainingIncome,
          bracket.maxIncome - (taxBrackets[i - 1]?.maxIncome || 0),
        );
        totalTax += taxableIncome * bracket.rate;
        remainingIncome -= taxableIncome;
      }
    }
    // Calculate tax percentage
    if(taxType === 'individu') {
      taxPercentage = (totalTax / totalTranscation) * 100 * (0.5 / 100);
      setTaxPercentage(taxPercentage);
    } else if (taxType === 'badan') {
      if (skbBadan) {
        taxPercentage = 0;
      } else if (suketPP23) {
        taxPercentage = 0.5 / 100;
      } else {
        taxPercentage = 2 / 100;
      }
      
      setTaxPercentage(taxPercentage);
    }

    if (grossNett === 'gross') {
      setFinalAmount(totalTranscation - totalTranscation * taxPercentage);
    } else {
      setFinalAmount(totalTranscation + totalTranscation * taxPercentage);
    }
  }

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('payment_method_id', values.payment_method_id);
    formData.append('dealing_rule_id', values.dealing_rule_id);
    formData.append('tax_type', values.tax_type);
    formData.append('tax_percentage', `${taxPercentage}`);
    formData.append('tax_calculation_basis', values.gross_nett);

    if (kolData?.spk_mou_type === 'spk_unsigned') {
      formData.append('spk_signed', values.contract_agreement);
    } else if (kolData?.spk_mou_type === 'mou_unsigned') {
      formData.append('mou_signed', values.contract_agreement);
    }

    if (identity === 'KTP') {
      formData.append('ktp', values.identity_file);
    } else if (identity === 'NPWP') {
      formData.append('npwp', values.identity);
    }

    if (taxType === 'badan') {
      if (skbBadan) {
        formData.append('skb', values.skb_file);
      } else if (suketPP23) {
        formData.append('suket_pp23', values.suket_pp23_file);
      }
    }

    try {
      // @ts-ignore
      console.table([...formData]);
      await postSecondKolForm(formData, id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    // @ts-ignore
    <>
      {isError ? (
        <Flex
          height="100vh" // Full viewport height to center vertically
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
        >
          <Box textAlign="center">
            {' '}
            {/* Center text alignment inside the box */}
            <Heading color={textColor} fontSize="36px" mb="10px">
              Maaf, kamu sudah mengisi form ini
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Silahkan hubungi PIC anda!
            </Text>
          </Box>
        </Flex>
      ) : (
        // @ts-ignore
        <Webformllustration
          illustrationBackground={illustration_}
          image={illustration_}
        >
          {isLoading ? (
            <Loader opacity={1}></Loader>
          ) : (
            <Flex
              maxW={{ base: '100%', md: 'max-content' }}
              w="100%"
              mx={{ base: 'auto', lg: '0px' }}
              me="auto"
              h="100%"
              alignItems="start"
              justifyContent="center"
              mb={{ base: '30px', md: '60px' }}
              px={{ base: '25px', md: '0px' }}
              mt={{ base: '40px', md: '14vh' }}
              flexDirection="column"
            >
              <Box me="auto">
                <Heading color={textColor} fontSize="36px" mb="10px">
                  KOL Form Details
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColorSecondary}
                  fontWeight="400"
                  fontSize="md"
                >
                  Please provide your profile details
                </Text>
              </Box>
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: '100%', md: '700px' }} // adjust form box width
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: 'auto', lg: 'unset' }}
                me="auto"
                mb={{ base: '20px', md: 'auto' }}
              >
                <Flex align="center" mb="25px">
                  <HSeparator />
                  <Text color="gray.400" mx="14px">
                    {/* or */}
                  </Text>
                  <HSeparator />
                </Flex>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                  }}
                >
                  {({ isSubmitting, handleChange, setFieldValue, values }) => (
                    <Form>
                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 1 */}
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Username</FormLabel>
                            <Input
                              readOnly
                              value={values.username}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Platform</FormLabel>
                            <Input
                              readOnly
                              value={values.platform}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Business Type</FormLabel>
                            <Input
                              readOnly
                              value={values.business_type}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Brand</FormLabel>
                            <Input
                              readOnly
                              value={values.brand}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                          <FormControl mb={4}>
                            <FormLabel>PIC</FormLabel>
                            <Input
                              readOnly
                              value={values.pic}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <FormLabel mt={4} fontSize="lg" fontWeight="bold">
                        Contract Info
                      </FormLabel>

                      <SimpleGrid
                        columns={{ base: 1, md: 6 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 3 */}
                        {values.contract_info &&
                          values.contract_info.map((item, key) => (
                            <React.Fragment key={key}>
                              <GridItem colSpan={2}>
                                <FormControl mb={4}>
                                  <FormLabel>Type</FormLabel>
                                  <Input
                                    readOnly
                                    value={item.type}
                                    _readOnly={readOnlyColor}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={3}>
                                <FormControl mb={4}>
                                  <FormLabel>Final Price</FormLabel>
                                  <Input
                                    value={
                                      item.final_rate_card_agreements.price
                                    }
                                    readOnly
                                    _readOnly={readOnlyColor}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl mb={4}>
                                  <FormLabel>Final Slot</FormLabel>
                                  <Input
                                    readOnly
                                    _readOnly={readOnlyColor}
                                    value={item.final_rate_card_agreements.slot}
                                  />
                                </FormControl>
                              </GridItem>
                            </React.Fragment>
                          ))}
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <FormLabel>Full Name</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.full_name}
                          />
                        </GridItem>
                        <GridItem>
                          <FormLabel>Email</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.email}
                          />
                        </GridItem>
                        <GridItem>
                          <FormLabel>Whatsapp Number</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.whatsapp_number}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 5 */}
                        <GridItem colSpan={3}>
                          <FormLabel>Home Address</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.home_address}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 6 */}
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Bank Name</FormLabel>
                            <Input
                              readOnly
                              _readOnly={readOnlyColor}
                              value={values.bank_name}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormLabel>Bank Account Name</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.bank_account_name}
                          />
                        </GridItem>
                        <GridItem>
                          <FormLabel>Bank Account Number</FormLabel>
                          <Input
                            readOnly
                            _readOnly={readOnlyColor}
                            value={values.bank_account_number}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 7 */}
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>File Invoice</FormLabel>
                            <Button
                              width="100%"
                              backgroundColor="gray.300"
                              onClick={() =>
                                window.open(values.invoice_file, '_blank')
                              }
                            >
                              See File
                            </Button>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <Flex alignItems="center">
                            <FormLabel marginBottom="0">
                              File Perjanjian Kontrak
                            </FormLabel>
                            <Tooltip
                              label="Silahkan download file ini lalu tanda tangani dan upload kembali pada 
                              field “Upload Perjanjian Kontrak“"
                              fontSize="md"
                              borderRadius="md"
                              isOpen={isTooltipOpen}
                            >
                              <InfoIcon
                                ref={iconRef}
                                marginLeft="2"
                                color="gray.500"
                                onClick={toggleTooltip}
                              />
                            </Tooltip>
                          </Flex>
                          <Button
                            mt={2}
                            width="100%"
                            backgroundColor="gray.300"
                            onClick={() =>
                              window.open(values.spk_mou_file, '_blank')
                            }
                          >
                            Download File
                          </Button>
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <FormControl mb={4} isRequired>
                            <FormLabel>Payment Method</FormLabel>
                            <Select
                              name="payment_method"
                              placeholder="Select payment method"
                              value={values.payment_method_id}
                              borderColor={borderColor}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  'payment_method_id',
                                  e.target.value,
                                );
                              }}
                            >
                              {kolData?.payment_methods?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4} isRequired>
                            <FormLabel>Contract Dealing Rule</FormLabel>
                            <Select
                              name="contract_dealing_rule"
                              placeholder="Select dealing rule"
                              value={values.dealing_rule_id}
                              borderColor={borderColor}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  'dealing_rule_id',
                                  e.target.value,
                                );
                              }}
                            >
                              {kolData?.dealing_rules?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <CustomUploadForm
                              label="Upload Perjanjian Kontrak"
                              id={'contract_agreement'}
                              values={values}
                              file_type={'application/pdf'}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <FormControl mb={4} isRequired>
                            <FormLabel>Tax Type</FormLabel>
                            <Select
                              name="tax_type"
                              placeholder="-"
                              value={values.tax_type}
                              borderColor={borderColor}
                              onChange={(e) => {
                                handleChange(e);
                                setTaxType(e.target.value);
                                setIdentity('NPWP');
                              }}
                            >
                              <option value="individu">Individu</option>
                              <option value="badan">Badan</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                        {taxType === 'badan' && (
                          <>
                            <GridItem>
                              <FormControl mb={4} isRequired>
                                <FormLabel>SKB (Badan)</FormLabel>
                                <Select
                                  name="skb_badan"
                                  value={skbBadan}
                                  borderColor={borderColor}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSkbBadan(e.target.value);
                                    setSuketPP23(
                                      e.target.value === 'true' &&
                                        suketPP23 === 'true' &&
                                        'false',
                                    );
                                  }}
                                >
                                  <option value="true">Ada</option>
                                  <option value="false">Tidak Ada</option>
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl mb={4} isRequired>
                                <FormLabel>Suket PP23 (Badan)</FormLabel>
                                <Select
                                  name="suket_pp23"
                                  value={suketPP23}
                                  borderColor={borderColor}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSuketPP23(e.target.value);
                                    setSkbBadan(
                                      e.target.value === 'true' &&
                                        skbBadan === 'true' &&
                                        'false',
                                    );
                                  }}
                                >
                                  <option value="true">Ada</option>
                                  <option value="false">Tidak Ada</option>
                                </Select>
                              </FormControl>
                            </GridItem>
                          </>
                        )}
                        <GridItem>
                          <FormControl mb={4} isRequired>
                            <FormLabel>Gross/Net</FormLabel>
                            <Select
                              name="gross_nett"
                              placeholder="-"
                              value={values.gross_nett}
                              borderColor={borderColor}
                              onChange={(e) => {
                                handleChange(e);
                                setGrossNett(e.target.value);
                              }}
                            >
                              <option value="gross">Gross</option>
                              <option value="nett">Nett</option>
                            </Select>
                          </FormControl>
                        </GridItem>
                        {taxType === 'individu' && (
                          <GridItem>
                            <FormControl mb={4} isRequired>
                              <FormLabel>KTP/NPWP</FormLabel>
                              <Select
                                name="identity"
                                placeholder="-"
                                value={values.identity}
                                borderColor={borderColor}
                                onChange={(e) => {
                                  handleChange(e);
                                  setIdentity(e.target.value);
                                }}
                              >
                                <option value="KTP">KTP</option>
                                <option value="NPWP">NPWP</option>
                              </Select>
                            </FormControl>
                          </GridItem>
                        )}
                        {taxType &&
                          (values.identity != undefined ||
                            taxType === 'badan') && (
                            <GridItem>
                              <CustomUploadForm
                                label={`Upload File ${
                                  values.tax_type === 'badan'
                                    ? 'NPWP'
                                    : values.identity
                                }`}
                                id={'identity_file'}
                                values={values}
                              />
                            </GridItem>
                          )}
                        {taxType === 'badan' && skbBadan === 'true' && (
                          <GridItem>
                            <CustomUploadForm
                              label="Upload File SKB Badan"
                              id={'skb_file'}
                              values={values}
                            />
                          </GridItem>
                        )}
                        {taxType === 'badan' && suketPP23 === 'true' && (
                          <GridItem>
                            <CustomUploadForm
                              label="Upload File Suket PP23"
                              id={'suket_pp23_file'}
                              values={values}
                            />
                          </GridItem>
                        )}
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 9 */}
                        {taxType && grossNett && identity && (
                          <>
                            <GridItem>
                              <FormControl mb={4}>
                                <FormLabel>Contract Total</FormLabel>
                                <Input
                                  value={values.contract_total_transaction}
                                  borderColor={borderColor}
                                  readOnly
                                  _readOnly={readOnlyColor}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl mb={4}>
                                <FormLabel>Tax Percentage (%)</FormLabel>
                                <Input
                                  value={taxPercentage * 100}
                                  borderColor={borderColor}
                                  readOnly
                                  _readOnly={readOnlyColor}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl mb={4}>
                                <FormLabel>Final Total</FormLabel>
                                <Input
                                  value={finalAmount}
                                  borderColor={borderColor}
                                  readOnly
                                  _readOnly={readOnlyColor}
                                />
                              </FormControl>
                            </GridItem>
                          </>
                        )}
                      </SimpleGrid>

                      <div>
                        <Button
                          type="submit"
                          fontSize="sm"
                          variant="brand"
                          fontWeight="500"
                          w="100%"
                          h="50"
                          mt="24px"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Flex>
            </Flex>
          )}
        </Webformllustration>
      )}
    </>
  );
}

function CustomUploadForm({
  values,
  label,
  id,
  file_type = 'image/png, image/jpeg, image/jpg, pdf', 
}) {
  return (
    <FormControl id="file-upload" mb={4} isRequired>
      <FormLabel>{label}</FormLabel>
      <Button
        as="label"
        htmlFor={id}
        colorScheme="purple"
        variant="outline"
        cursor="pointer"
        width="100%"
      >
        Choose File
      </Button>
      <Field name={id}>
        {({ form }) => (
          <>
            <Input
              id={id}
              name={id}
              type="file"
              accept={file_type}
              isRequired={true}
              onChange={(event) => {
                form.setFieldValue(id, event.currentTarget.files[0]);
              }}
              style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                padding: 0,
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                border: 0,
              }}
            />
            {form.errors[id] && form.touched[id] && (
              <Text color="red.500">{form.errors[id]}</Text>
            )}
          </>
        )}
      </Field>
      {values[id] && (
        <Text mt={2} color="gray.600">
          Selected file: {values[id].name}
        </Text>
      )}
    </FormControl>
  );
}

function Webformllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '200vh',
          xl: '180vh',
        }}
        w="100%"
        maxW={{ sm: '85%', md: '80%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '20vw', '2xl': '30vw' }} // Reduced width here
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          ></Flex>
        </Box>
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}

Webformllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};
