export const columnsDataDevelopment = [
  {
    Header: "LISTING DATE",
    accessor: "listing_date",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "PLATFORM",
    accessor: "platform",
  },
  {
    Header: "RATE CARD",
    accessor: "rate_card",
  },
  {
    Header: "ADD COST",
    accessor: "add_cost",
  },
  {
    Header: "KOL CATEGORY",
    accessor: "kol_category",
  },
  {
    Header: "NOTE",
    accessor: "note",
  },
  {
    Header: "CPM",
    accessor: "cpm",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];
