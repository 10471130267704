import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Text,
  useColorModeValue,
  Select,
  useToast,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';

// Assets
import illustration_ from 'assets/img/auth/auth-3.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuth';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import useKolForm from 'hooks/useKolForm';
import Loader from 'components/loader/Loader';
import CustomInputForm from 'components/fields/TextInputForm';
import DateInputForm from 'components/fields/DateInputForm';

// Chakra color mode
const textColorSecondary = 'gray.400';
const borderColor = 'gray.300';
const readOnlyColor = {
  bg: 'transparant',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

export default function KolFormFirst() {
  const textColor = useColorModeValue('navy.700', 'white');
  const { isError, isLoading, kolData, getFirstKolFormData, postFirstKolForm } =
    useKolForm();
  const { id } = useParams();
  const toast = useToast();

  const [taxPercentage, setTaxPercentage] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  useEffect(() => {
    try {
      getFirstKolFormData(id);
    } catch (error) {}
  }, [id, isError, isLoading, taxPercentage, finalAmount]);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('Full name is required')
      .min(3, 'Must be at least 3 characters'),
    email: Yup.string()
      .email('Invalid email address')
      .required('email is required'),
    whatsapp_number: Yup.number()
      .required('Whatsapp number is required')
      .min(9, 'Must be at least 9 numbers'),
    home_address: Yup.string()
      .required('Home address is required')
      .min(10, 'Must be at least 10 characters'),
    bank_account_name: Yup.string()
      .required('Bank account name is required')
      .min(3, 'Must be at least 3 characters'),
    bank_account_number: Yup.number()
      .required('Bank account number is required')
      .min(4, 'Must be at least 4 numbers'),
  });

  const initialValues = {
    username: kolData?.username,
    platform: kolData?.platform,
    brand: kolData?.brand,
    business_type: kolData?.business_type,
    pic: kolData?.pic,
    contract_info: kolData?.contract_info,
    contract_number: kolData?.contract_number,
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('full_name', values.full_name);
    formData.append('email', values.email);
    formData.append('whatsapp_number', values.whatsapp_number);
    formData.append('home_address', values.home_address);
    formData.append('bank_id', values.bank_id);
    formData.append('bank_account_name', values.bank_account_name);
    formData.append('bank_account_number', values.bank_account_number);
    formData.append('nik', values.nik);
    formData.append('born_at', values.born_at);
    formData.append('birth_date', values.birth_date);
    formData.append('invoice', values.invoice || null);
    
    try {
      // @ts-ignore
      console.table([...formData]);
      await postFirstKolForm(formData, id);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    // @ts-ignore
    <>
      {false ? (
        <Flex
          height="100vh" // Full viewport height to center vertically
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
        >
          <Box textAlign="center">
            {' '}
            {/* Center text alignment inside the box */}
            <Heading color={textColor} fontSize="36px" mb="10px">
              You already filled the form!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Please contact your PIC
            </Text>
          </Box>
        </Flex>
      ) : (
        // @ts-ignore
        <Webformllustration
          illustrationBackground={illustration_}
          image={illustration_}
        >
          {isLoading ? (
            <Loader opacity={1}></Loader>
          ) : (
            <Flex
              maxW={{ base: '100%', md: 'max-content' }}
              w="100%"
              mx={{ base: 'auto', lg: '0px' }}
              me="auto"
              h="100%"
              alignItems="start"
              justifyContent="center"
              mb={{ base: '30px', md: '90px' }}
              px={{ base: '25px', md: '0px' }}
              mt={{ base: '40px', md: '14vh' }}
              flexDirection="column"
            >
              <Box me="auto">
                <Heading color={textColor} fontSize="36px" mb="10px">
                  KOL Form 1
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColorSecondary}
                  fontWeight="400"
                  fontSize="md"
                >
                  Please provide your profile details
                </Text>
              </Box>
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: '100%', md: '700px' }} // adjust form box width
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: 'auto', lg: 'unset' }}
                me="auto"
                mb={{ base: '20px', md: 'auto' }}
              >
                <Flex align="center" mb="25px">
                  <HSeparator />
                  <Text color="gray.400" mx="14px">
                    {/* or */}
                  </Text>
                  <HSeparator />
                </Flex>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                  }}
                >
                  {({ isSubmitting, handleChange, setFieldValue, values }) => (
                    <Form>
                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 1 */}
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Username</FormLabel>
                            <Input
                              value={values.username}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Platform</FormLabel>
                            <Input
                              value={values.platform}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Business Type</FormLabel>
                            <Input
                              value={values.business_type}
                              readOnly
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Brand</FormLabel>
                            <Input
                              readOnly
                              value={values.brand}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                          <FormControl mb={4}>
                            <FormLabel>PIC</FormLabel>
                            <Input
                              readOnly
                              value={values.pic}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <FormLabel mt={4} fontSize="lg" fontWeight="bold">
                        Contract Info
                      </FormLabel>

                      <SimpleGrid
                        columns={{ base: 1, md: 6 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 3 */}
                        {values.contract_info &&
                          values.contract_info.map((item, key) => (
                            <React.Fragment key={key}>
                              <GridItem colSpan={2}>
                                <FormControl mb={4}>
                                  <FormLabel>Type</FormLabel>
                                  <Input
                                    readOnly
                                    value={item.type}
                                    _readOnly={readOnlyColor}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={3}>
                                <FormControl mb={4}>
                                  <FormLabel>Final Price</FormLabel>
                                  <Input
                                    value={
                                      'Rp' +
                                      (item.final_rate_card_agreements?.price ||
                                        0)
                                    }
                                    readOnly
                                    _readOnly={readOnlyColor}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl mb={4}>
                                  <FormLabel>Final Slot</FormLabel>
                                  <Input
                                    readOnly
                                    _readOnly={readOnlyColor}
                                    value={
                                      item.final_rate_card_agreements?.slot || 0
                                    }
                                  />
                                </FormControl>
                              </GridItem>
                            </React.Fragment>
                          ))}
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Full Name'}
                            id={'full_name'}
                          />
                        </GridItem>
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Email'}
                            id={'email'}
                          />
                          <ErrorMessage
                            name="email"
                            component={FormErrorMessage}
                          />
                        </GridItem>
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Whatsapp Number'}
                            id={'whatsapp_number'}
                            isNumber={true}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 5 */}
                        <GridItem colSpan={3}>
                          <CustomInputForm
                            values={values}
                            label={'Home Address'}
                            id={'home_address'}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 6 */}
                        <GridItem>
                          <FormControl mb={4} isRequired>
                            <FormLabel>Bank Name</FormLabel>
                            <Select
                              name="bank_name"
                              placeholder="Select bank name"
                              value={values.bank_id}
                              borderColor={borderColor}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('bank_id', e.target.value);
                              }}
                            >
                              {kolData?.banks?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Bank Account Name'}
                            id={'bank_account_name'}
                          />
                        </GridItem>
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Bank Account Number'}
                            id={'bank_account_number'}
                            isNumber={true}
                          />
                        </GridItem>
                      </SimpleGrid>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 7 */}
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'NIK'}
                            id={'nik'}
                            isNumber={true}
                          />
                        </GridItem>
                        <GridItem>
                          <CustomInputForm
                            values={values}
                            label={'Tempat Lahir'}
                            id={'born_at'}
                          />
                        </GridItem>
                        <GridItem>
                          <DateInputForm
                            values={values}
                            label={'Tanggal Lahir'}
                            id={'birth_date'}
                            setFieldValue={setFieldValue}
                          />
                        </GridItem>
                      </SimpleGrid>
                      <SimpleGrid
                        columns={{ base: 1, md: 6 }}
                        spacingX={5}
                        spacingY={5}
                        mb="20px"
                      >
                        {/* Row 6 */}
                        <GridItem colSpan={2}>
                          <CustomUploadForm
                            label={`Upload Invoice (Bila Ada)`}
                            id={'invoice'}
                            values={values}
                          />
                        </GridItem>
                        <GridItem>
                          <FormControl mb={4}>
                            <FormLabel>Contract No</FormLabel>
                            <Input
                              readOnly
                              value={values.contract_number}
                              _readOnly={readOnlyColor}
                            />
                          </FormControl>
                        </GridItem>
                      </SimpleGrid>

                      <div>
                        <Button
                          type="submit"
                          fontSize="sm"
                          variant="brand"
                          fontWeight="500"
                          w="100%"
                          h="50"
                          mt="24px"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Flex>
            </Flex>
          )}
        </Webformllustration>
      )}
    </>
  );
}

function CustomUploadForm({ values, label, id}) {
  return (
    <FormControl id="file-upload" mb={4}>
      <FormLabel>{label}</FormLabel>
      <Button
        as="label"
        htmlFor={id}
        colorScheme="purple"
        variant="outline"
        cursor="pointer"
        width="100%"
      >
        Choose File
      </Button>
      <Field name={id}>
        {({ form }) => (
          <>
            <Input
              id={id}
              name={id}
              type="file"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              isRequired={true}
              onChange={(event) => {
                form.setFieldValue(id, event.currentTarget.files[0]);
              }}
              style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                padding: 0,
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                border: 0,
              }}
            />
            {form.errors[id] && form.touched[id] && (
              <Text color="red.500">{form.errors[id]}</Text>
            )}
          </>
        )}
      </Field>
      {values[id] && (
        <Text mt={2} color="gray.600">
          Selected file: {values[id].name}
        </Text>
      )}
    </FormControl>
  );
}

function Webformllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '150vh',
          xl: '150vh',
        }}
        w="100%"
        maxW={{ sm: '85%', md: '80%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '20vw', '2xl': '30vw' }} // Reduced width here
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          ></Flex>
        </Box>
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}

Webformllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};
