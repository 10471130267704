import React from 'react';
import {
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';

function DetailCardNegotiate({ title, components }) {
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <>
      <Menu>
        <MenuButton>
          <Text color="purple.500" cursor="pointer" textDecoration="underline">
            show
          </Text>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%">
            <Text
              pl="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              {title}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {components.map((item, key) => {
              const formattedSubtitle = item.subtitle.includes('_')
                ? item.subtitle.replace(/_/g, ' ')
                : item.subtitle;
              return (
                <>
                  <Text
                    fontSize="sm"
                    textTransform="capitalize"
                    color={textColor}
                    fontWeight="bold"
                    pt="10px"
                    pl="20px"
                  >
                    {formattedSubtitle}
                  </Text>
                  {components.length > 0 ? (
                    <SubItem item={item.subitems}></SubItem>
                  ) : (
                    <Text
                      fontSize="sm"
                      textTransform="capitalize"
                      color={textColor}
                      fontWeight="bold"
                      pt="10px"
                      pl="20px"
                    >
                      No Data
                    </Text>
                  )}
                </>
              );
            })}
          </Flex>
        </MenuList>
      </Menu>
    </>
  );
}

function SubItem({ item }) {
  return (
    <>
      <MenuItem
        _hover={{ bg: 'none' }}
        _focus={{ bg: 'none' }}
        borderRadius="8px"
        px="20px"
        borderBottom="1px solid"
        borderColor={'gray.300'}
      >
        <Text
          fontSize="sm"
          whiteSpace="pre-wrap"
          textTransform="capitalize"
          color={'gray.700'}
        >
          {item}
        </Text>
      </MenuItem>
    </>
  );
}

export default DetailCardNegotiate;
