import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from 'theme/theme'; //  { themeGreen }
import { useContext, useState } from 'react';
import React from 'react';
import Login from 'views/Login';
import ListingManager from 'views/manager/listing';
import CalendarComponent from 'views/manager/timeline';
import { AuthContext, AuthProvider } from 'contexts/AuthContext';
import SidebarRoute from 'routes/SidebarRoute';
import Loader from 'components/loader/Loader';
import Listing from 'views/kol_specialist/listing';
import Dealing from 'views/kol_specialist/negotiate';
import FirstDealingInformation from 'views/kol_specialist/first_dealing_information';
import FirstDealingVerification from 'views/kol_specialist/first_dealing_verification';
import KolFormFirst from 'views/KolFormFirst';
import KolFormSecond from 'views/KolFormSecond';
import Shipping from 'views/kol_specialist/shipping';
import ShippingShipper from 'views/shipper/shipping';
import IdeationKol from 'views/kol_specialist/ideation';
import IdeationKolAddForm from 'views/kol_specialist/ideation/IdeationAddForm';
import IdeationKolEditForm from 'views/kol_specialist/ideation/IdeationEditForm';
import IdeationManager from 'views/manager/ideation';
import IdeationManagerEditForm from 'views/manager/ideation/IdeationEditForm';
import SecondDealingInformation from 'views/kol_specialist/second_dealing_information';
import SecondDealingVerification from 'views/kol_specialist/second_dealing_verification';

export default function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <AuthProvider>
      <ChakraProvider theme={currentTheme}>
        <Routes>
          <Route path="/" element={<RoleBasedRedirect />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/kol/form/1/:id" element={<KolFormFirst />} />
          <Route path="/kol/form/2/:id" element={<KolFormSecond />} />

          <Route element={<SidebarRoute />}>
            <Route path="/ideation/kol" element={<ProtectedRoute path_name="/ideation/kol" component={IdeationKol} />}/>
            <Route path="/ideation/kol/add" element={<ProtectedRoute path_name="/ideation/kol/add" component={IdeationKolAddForm} />}/>
            <Route path="/ideation/kol/edit/:id" element={<ProtectedRoute path_name="/ideation/kol/edit" component={IdeationKolEditForm} />}/>
            <Route path="/ideation/manager" element={<ProtectedRoute path_name="/ideation/manager" component={IdeationManager} />}/>
            <Route path="/ideation/manager/edit/:id" element={<ProtectedRoute path_name="/ideation/manager/edit" component={IdeationManagerEditForm} />}/>

            <Route path="/listing/kol" element={ <ProtectedRoute path_name="/listing/kol" component={Listing} />}/>
            <Route path="/listing/manager" element={<ProtectedRoute path_name="/listing/manager" component={ListingManager}/>}/>
            
            <Route path="/negotiate/kol" element={<ProtectedRoute path_name="/negotiate/kol" component={Dealing} /> }/>
            <Route path="/dealing/kol/first/information" element={<ProtectedRoute path_name="/dealing/kol/first/information" component={FirstDealingInformation}/> }/>
            <Route path="/dealing/kol/first/verification" element={<ProtectedRoute path_name="/dealing/kol/first/verification" component={FirstDealingVerification}/> }/>
            <Route path="/dealing/kol/second/information" element={<ProtectedRoute path_name="/dealing/kol/second/information" component={SecondDealingInformation}/> }/>
            <Route path="/dealing/kol/second/verification" element={<ProtectedRoute path_name="/dealing/kol/second/verification" component={SecondDealingVerification}/> }/>
            
            <Route path="/shipping/kol" element={<ProtectedRoute path_name="/shipping/kol" component={Shipping} /> }/>
            <Route path="/shipping/shipper" element={<ProtectedRoute path_name="/shipping/shipper" component={ShippingShipper} /> }/>
            <Route path="/payment" element={<ProtectedRoute path_name="/payment" component={CalendarComponent}/> }/>
            <Route path="/timeline" element={<ProtectedRoute  path_name="/timeline"  component={CalendarComponent}/> }/>
          </Route>
        </Routes>
      </ChakraProvider>
    </AuthProvider>
  );
}

const RoleBasedRedirect = () => {
  const { isLoading, userAccess } = useContext(AuthContext);

  if (isLoading) {
    return <Loader></Loader>;
  }

  if (!userAccess) {
    return <Navigate to="/login" />;
  }

  return <Navigate to={`${userAccess[0].path_name}`} />;
};

const ProtectedRoute = ({ component: Component, path_name }) => {
  const { userAccess, postLogout, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <Loader></Loader>;
  }

  if (!userAccess) {
    return <Navigate to="/login" />;
  }

  if (!userAccess.some((item) => item.path_name === path_name)) {
    return <Navigate to="/" />;
  }

  if (userAccess) {
    return <Component />;
  }
};
