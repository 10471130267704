import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  GridItem,
  Textarea,
  Flex,
  Menu,
  Text,
  MenuItem,
  MenuList,
  MenuButton,
} from '@chakra-ui/react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import useShipping from 'hooks/useShipping';
import { MdAddCircle } from 'react-icons/md';

const readOnlyColor = {
  bg: 'transparant',
  color: 'gray.500',
  borderColor: 'gray.300',
  textTransform: 'capitalize',
};

const FormComponent = ({ onClose, toast }) => {
  const {
    products,
    kolData,
    kolDetailData,
    getKolByPlatform,
    getKolDetail,
    getAllProducts,
    postShipping,
  } = useShipping();

  useEffect(() => {
    getAllProducts();
  }, [kolDetailData]);

  const handlePlatformChange = async (selectedPlatform, setFieldValue) => {
    try {
      console.log(selectedPlatform);
      await getKolByPlatform(selectedPlatform);
    } catch (error) {
      console.error('Error fetching KOL data:', error);
    }
  };

  const handleKolChange = async (index, setFieldValue) => {
    try {
      const data = await getKolDetail(index);
    } catch (error) {}
  };

  const initialValues = {
    selectedProducts: [],
  };

  const onSubmit = async (values) => {
    const payload = {
      kol_id: Number(values.kol_id),
      is_urgent: Boolean(values.is_urgent),
      shipping_type_id: Number(values.shipping_type_id),
      products: values.selectedProducts.map(({ id, quantity }) => ({
        product_id: id,
        quantity: parseInt(quantity, 10),
      })),
      note: null,
    };

    console.log(payload);

    try {
      await postShipping(payload);
      toast({
        title: 'Form submitted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setTimeout(() => {
        onClose();
      }, 3000); // Same duration as the toast
    } catch (error) {
      toast({
        title: 'Cannot sending form!',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <SimpleGrid columns={3} spacingX={5} spacingY={5} mb="20px">
                {/* Row 1 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Platform</FormLabel>
                    <Select
                      placeholder="Select platform"
                      name="platform"
                      onChange={(e) => {
                        handleChange(e);
                        handlePlatformChange(e.target.value, setFieldValue);
                      }}
                      value={values.platform}
                    >
                      <option value="tiktok">Tiktok</option>
                      <option value="instagram">Instagram</option>
                      <option value="twitter">Twitter</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>KOL</FormLabel>
                    <Select
                      placeholder="Select KOL"
                      name="kol_id"
                      onChange={(e) => {
                        handleChange(e);
                        handleKolChange(e.target.value, setFieldValue);
                      }}
                      style={{ textTransform: 'capitalize' }}
                      value={values.kol_id}
                    >
                      {kolData &&
                        kolData.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.username}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Full Name</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={kolDetailData.full_name || ''}
                    />
                  </FormControl>
                </GridItem>

                {/* Row 2 */}
                <GridItem colSpan={3}>
                  <FormControl mb={4}>
                    <FormLabel>Home Address</FormLabel>
                    <Textarea
                      readOnly
                      _readOnly={readOnlyColor}
                      value={kolDetailData.home_address || ''}
                      resize="none" // Prevents horizontal resizing, only allows vertical expansion
                      rows={1} // Initial row count
                      height="60px"
                    />
                  </FormControl>
                </GridItem>

                {/* Row 3 */}
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      readOnly
                      _readOnly={readOnlyColor}
                      value={kolDetailData.whatsapp_number || ''}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Is Urgent</FormLabel>
                    <Select
                      placeholder="Select urgency"
                      name="is_urgent"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.is_urgent}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mb={4}>
                    <FormLabel>Shipping Type</FormLabel>
                    <Select
                      name="shipping_type_id"
                      placeholder="Select shipping type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.shipping_type_id}
                    >
                      <option value={1}>Same Day</option>
                      <option value={2}>Regular</option>
                    </Select>
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              {/* Row 4 */}
              <Flex justify="start" alignItems="center" mb={4}>
                <Box>
                  <FormLabel fontSize="lg" fontWeight="bold" mb="0">
                    Product to add
                  </FormLabel>
                </Box>
                <Box ml={2}>
                  <FormControl>
                    <Menu>
                      <MenuButton
                        as={Box}
                        cursor="pointer"
                        display="flex"
                        alignItems="center"
                      >
                        <MdAddCircle size="18px" />
                      </MenuButton>
                      <MenuList>
                        {products
                          .filter(
                            (product) =>
                              !values.selectedProducts.some(
                                (selectedProduct) =>
                                  selectedProduct.id === product.id,
                              ),
                          )
                          .map((product) => (
                            <MenuItem
                              key={product.id}
                              onClick={() => {
                                const alreadySelected =
                                  values.selectedProducts.find(
                                    (p) => p.id === product.id,
                                  );
                                if (!alreadySelected) {
                                  setFieldValue('selectedProducts', [
                                    ...values.selectedProducts,
                                    { ...product, quantity: 1 },
                                  ]);
                                }
                              }}
                            >
                              {product.name}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Menu>
                  </FormControl>
                </Box>
              </Flex>

              {/* Display Selected Products */}
              <FieldArray name="selectedProducts">
                {({ remove, replace }) => (
                  <>
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      spacingX={5}
                      spacingY={5}
                    >
                      {values.selectedProducts.length != 0 && (
                        <>
                          <GridItem>
                            <FormLabel>Product Name</FormLabel>
                          </GridItem>
                          <GridItem colSpan={2}>
                            <FormLabel>Quantity</FormLabel>
                          </GridItem>
                        </>
                      )}
                    </SimpleGrid>
                    <SimpleGrid
                      columns={{ base: 1, md: 9 }}
                      spacingX={5}
                      spacingY={3}
                      alignItems="center"
                    >
                      {values.selectedProducts.map((product, index) => (
                        <React.Fragment key={product.id}>
                          <GridItem colSpan={3}>
                            <FormControl>
                              <Input readOnly value={product.name} />
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <Input
                                type="number"
                                value={product.quantity}
                                onChange={(e) =>
                                  replace(index, {
                                    ...product,
                                    quantity: e.target.value,
                                  })
                                }
                                mr={2}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={5}>
                            <Text
                              color="red.500"
                              onClick={() => remove(index)}
                              cursor="pointer"
                              textDecoration="underline"
                            >
                              remove
                            </Text>
                          </GridItem>
                        </React.Fragment>
                      ))}
                    </SimpleGrid>
                  </>
                )}
              </FieldArray>

              <Box textAlign="right">
                <Button colorScheme="purple" type="submit">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default FormComponent;
