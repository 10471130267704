import { useState } from 'react';
import DatePicker from 'react-date-picker';
import '../../assets/css/DatePicker.css';
import { FormLabel, FormControl, Text } from '@chakra-ui/react';
import { Field, ErrorMessage } from 'formik';
import React from 'react';

export default function DateInputForm({ values, label, id, setFieldValue }) {
  const [value, onChange] = useState(new Date());

  // Function to format date as 'YYYY-MM-DD'
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    onChange(date);
    const formattedDate = formatDate(date); // Format the date to 'YYYY-MM-DD'
    setFieldValue(id, formattedDate); // Update Formik field value with the formatted date
  };

  return (
    <FormControl isRequired>
      <FormLabel>{label}</FormLabel>
      <Field name={id}>
        {({ field, form }) => (
          <div>
            <DatePicker
              minDate={new Date(1930, 0, 1)}
              maxDate={new Date()}
              onChange={handleDateChange}
              value={value}
              clearIcon={null}
              format={'dd-MM-y'} // The display format
            />
          </div>
        )}
      </Field>
      <ErrorMessage name={id}>
        {(msg) => (
          <Text color="red.500" mt={1} fontSize="sm">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  );
}
